import { ClockCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import RaffleUtils from '../../utils/raffle'

const CountDown = ({ timestamp }) => (
  <Tooltip title={moment.unix(timestamp).format('ddd, MMM Do YYYY, h:mm:ss a')}>
    <ClockCircleOutlined className="mr1" />
    <FormattedMessage
      id="page.raffle.days"
      values={{ days: RaffleUtils.getRemainingDays(timestamp) }}
    />
  </Tooltip>
)

CountDown.propTypes = {
  timestamp: PropTypes.number.isRequired,
}

export default CountDown
