import {
  Button,
  Form,
  Input,
  message,
} from 'antd'
import EmailValidator from 'email-validator'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { isEmpty } from 'lodash'
import RaffleActions from '../../actions/raffles'
import Recaptcha from '../Recaptcha'

const EnterToPlayInput = ({ raffle, onPlayerChange }) => {
  const handleSubmit = async (values) => {
    if (values.email && EmailValidator.validate(values.email)) {
      try {
        // get player details from server
        const newPlayer = await RaffleActions.getPlayer(raffle.id, values)
        // if successful, start the game
        if (isEmpty(newPlayer)) onPlayerChange({ view: 'invalid' })
        else onPlayerChange({ view: 'game', player: newPlayer })
      } catch (ex) {
        message.warn(ex.message)
      }
    } else {
      const msg = <FormattedMessage id="comp.rafflesidebar.invalidemail" />
      message.warn(msg)
    }
  }

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item name="email" rules={[{ required: true }]}>
        <Input
          className="mb3"
          placeholder="you@example.com"
          size="large"
          type="email"
        />
      </Form.Item>
      <Form.Item name="recaptcha" rules={[{ required: true }]}>
        <Recaptcha />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        <FormattedMessage id="comp.entertoplay.button.name" />
      </Button>
    </Form>
  )
}

EnterToPlayInput.propTypes = {
  raffle: PropTypes.object.isRequired,
  onPlayerChange: PropTypes.func.isRequired,
}

export default EnterToPlayInput
