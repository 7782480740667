import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  Alert,
  Tag,
  Typography,
  Card,
  Button,
  Divider,
  Steps,
  Result,
} from 'antd'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'

import {
  VideoCameraOutlined, SmileOutlined,
} from '@ant-design/icons'

import gameDemo from '../../assets/videos/gamedemo.mp4'
import RaffleUtils from '../../utils/raffle'

import Subtitle from '../RaffleDetails/Subtitle'

import EnterToPlayInput from './EnterToPlayInput'
import Game from './Game'

import clickSound from './click.mp3'
import rightSound from './right.mp3'
import wrongSound from './wrong.mp3'

const ContestDetails = ({
  raffle, view, player, onPlayerChange,
}) => {
  const [step, setStep] = useState(0)
  const { Step } = Steps

  const click = new Audio(clickSound)
  const right = new Audio(rightSound)
  const wrong = new Audio(wrongSound)

  const steps = [
    {
      title: 'Before You Start',
      content: <ReactPlayer
        url={gameDemo}
        controls
        width="100%"
      />,
      icon: <VideoCameraOutlined />,
      description: 'Take a look at our Demo',
    },
    {
      title: 'Enter To PLAY',
      content: <EnterToPlayInput raffle={raffle} onPlayerChange={onPlayerChange} />,
      icon: <SmileOutlined />,
      description: 'Enjoy Playing!',
    },
  ]

  const handleNextStep = () => {
    const i = step
    setStep(i + 1)
  }

  const handleBackStep = () => {
    const i = step
    setStep(i - 1)
  }

  const renderView = () => {
    if (view === 'enter-email') {
      return (
        <>
          {RaffleUtils.isLive(raffle) && (
          <div className="mx-auto" style={{ maxWidth: '500px' }}>
            <Card>
              <Steps current={step}>
                {steps.map(({
                  title, content, icon, description,
                }, i) => (
                  <Step
                    key={title}
                    title={title}
                    className={i === step ? 'fade-in' : ''}
                    icon={icon}
                    description={description}
                  >
                    {content}
                  </Step>
                ))}
              </Steps>
              <div className="steps-content">{steps[step].content}</div>
              <Divider />
              <div className="mt2">
                {step > 0 && (
                <Button type="primary" onClick={handleBackStep}>Back</Button>
                )}
                {step < steps.length - 1 && (
                <Button type="primary" onClick={handleNextStep}>Next</Button>
                )}
              </div>
            </Card>
          </div>
          )}
        </>
      )
    }
    if (view === 'game') {
      return (
        <Game
          raffle={raffle}
          player={player}
          click={click}
          right={right}
          wrong={wrong}
        />
      )
    }

    if (view === 'invalid') {
      return (
        <Result
          status="403"
          subTitle={<FormattedMessage id="comp.contest.invalid.email" />}
          extra={<Button type="primary" onClick={() => { window.location.reload() }}>Back Home</Button>}
          title="403"
        />
      )
    }
    return null
  }

  return (
    <>
      <Helmet
        meta={[
          { property: 'og:image', content: RaffleUtils.getImageSrc(raffle) },
          { property: 'og:title', content: raffle.title },
          { property: 'twitter:image:src', content: RaffleUtils.getImageSrc(raffle) },
          { property: 'twitter:title', content: raffle.title },
        ]}
      />
      {raffle.status === 'draft' && (
      <div className="mb2">
        <Alert
          description={<FormattedMessage id="comp.raffledetails.draft.desc" />}
          message={<FormattedMessage id="comp.raffledetails.draft.message" />}
          showIcon
          type="warning"
        />
      </div>
      )}
      {(!RaffleUtils.isPublished(raffle) && (
        <div className="mb2">
          {RaffleUtils.isUnsuccessful(raffle) && (
            <Alert
              description={(
                <Link state={{ industry: raffle.industry }} to={`/${raffle.industry}/`}>
                  <FormattedMessage id="comp.raffledetails.over.desc" />
                </Link>
              )}
              message={(
                <FormattedMessage
                  id="comp.raffledetails.backer.over.message"
                />
              )}
              showIcon
              type="info"
            />
          )}
          {RaffleUtils.isSuccessful(raffle) && (
            <Alert
              description={(
                <Link state={{ industry: raffle.industry }} to={`/${raffle.industry}/`}>
                  <FormattedMessage id="comp.raffledetails.over.desc" />
                </Link>
              )}
              message={(
                <FormattedMessage
                  id={`comp.raffledetails.${RaffleUtils.isPaid(raffle) ? 'contest' : 'giveaway'}.over.message`}
                />
              )}
              showIcon
              type="info"
            />
          )}
        </div>
      ))}
      {RaffleUtils.isPublished(raffle) && (
        <>
          <Typography.Title level={3}>
            {RaffleUtils.isBackerCampaign(raffle) && (
            <Tag color="volcano">
              <FormattedMessage id="common.backer-campaign" />
            </Tag>
            )}
            {raffle.title}
          </Typography.Title>
          <Subtitle raffle={raffle} />
          {renderView()}
        </>
      )}
    </>
  )
}

ContestDetails.propTypes = {
  raffle: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  player: PropTypes.object.isRequired,
  onPlayerChange: PropTypes.func.isRequired,
}

export default ContestDetails
