import React, { forwardRef } from 'react'
import ReactRecaptcha from 'react-recaptcha'

// eslint-disable-next-line react/prop-types
const Recaptcha = ({ onChange }, ref) => {
  const handleExpired = () => {
    if (onChange) {
      onChange(undefined)
    }
  }

  const handleVerify = (token) => {
    if (onChange) {
      onChange(token)
    }
  }

  const onloadCallback = () => {
    // Do Nothing
  }

  return (
    <ReactRecaptcha
      expiredCallback={handleExpired}
      ref={ref}
      sitekey="6LdDRYQUAAAAAKWb-hUqByWJRejemKIr7FGc0v6w"
      verifyCallback={handleVerify}
      render="explicit"
      onloadCallback={onloadCallback}
    />
  )
}

export default forwardRef(Recaptcha)
