import {
  Result,
  Skeleton,
  message,
  Row,
  Col,
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import Layout from '../../components/Layout'
import ContestDetails from '../../components/ContestDetails'

import RaffleActions from '../../actions/raffles'

const Contest = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [raffle, setRaffle] = useState()
  const [view, setView] = useState('enter-email')
  const [player, setPlayer] = useState({})

  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const id = location.state ? location.state.id : params.id
  const industry = location.state ? location.state.industry : params.industry

  const getRaffle = useCallback(
    async () => {
      try {
        setLoading(true)
        const newRaffle = await RaffleActions.get(industry, id)
        setLoading(false)

        setRaffle(newRaffle)
      } catch (ex) {
        message.warn(ex.message)
      }
    },
    [id, industry],
  )

  useEffect(() => {
    if (id && industry) {
      // get raffle details
      getRaffle()
    } else {
      // use empty object since the raffle get() will return an empty object if not found
      setRaffle({})
      setLoading(false)
    }
  }, [getRaffle, id, industry])

  const handlePlayerChange = ({ view: newView, player: newPlayer }) => {
    if (newPlayer) {
      setPlayer(newPlayer)
    }
    setView(newView)
  }

  const renderView = () => {
    // still loading
    if (loading) {
      return (
        <Skeleton />
      )
    }

    // after loading, the raffle is still empty
    // show 404 error message
    if (isEmpty(raffle)) {
      return (
        <Result
          status="404"
          subTitle={<FormattedMessage id="page.404.sorry" />}
          title="404"
        />
      )
    }

    return (
      <Row className="mt2" gutter={24}>
        <Col xs={24} md={24}>
          <ContestDetails
            raffle={raffle}
            view={view}
            player={player}
            onPlayerChange={handlePlayerChange}
          />
        </Col>
      </Row>
    )
  }

  return (
    <Layout>
      <div className="container my3">
        {renderView()}
      </div>
    </Layout>
  )
}

Contest.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Contest
