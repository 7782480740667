import { Divider, Progress, Typography } from 'antd'
import {
  ClockCircleOutlined,
  FieldTimeOutlined,
  GiftOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import RaffleUtils from '../../utils/raffle'

import CountDown from './CountDown'
import EntrantCount from './EntrantCount'
import MinBackerCount from './MinBackerCount'
import Locations from './Locations'

const Subtitle = ({ raffle }) => (
  <>
    <ul className="list-reset">
      <li>
        {RaffleUtils.isPaid(raffle) && (
        <FieldTimeOutlined className="mr1" />
        )}
        {!RaffleUtils.isPaid(raffle) && (
        <GiftOutlined className="mr1" />
        )}
        <Typography.Text strong>
          <FormattedMessage id={`common.raffle-type.${raffle.raffleType}`} strong />
        </Typography.Text>
      </li>
      <li>
        <EnvironmentOutlined className="mr1" />
        <Locations raffle={raffle} />
      </li>
      <li>
        <ClockCircleOutlined className="mr1" />
        {RaffleUtils.isBackerCampaign(raffle) ? (
          <>
            <FormattedMessage id="comp.rafflelist.pledge-open-until" />
            <Typography.Text className="ml1" strong>
              {moment.unix(raffle.startAt).format('MMM Do YYYY')}
            </Typography.Text>
          </>
        ) : (
          <>
            <FormattedMessage id="comp.rafflelist.participation-open-until" />
            <Typography.Text className="ml1" strong>
              {moment.unix(raffle.endAt).format('MMM Do YYYY')}
            </Typography.Text>
          </>
        )}
      </li>
      {!RaffleUtils.isLive(raffle) && RaffleUtils.needsBacker(raffle) && (
      <>
        <li>
          <MinBackerCount count={raffle.minBackers} />
          <Divider type="vertical" />
          <EntrantCount count={parseInt(raffle.entrantCount, 10) + parseInt(raffle.backerCount, 10)} />
        </li>
      </>
      )}
      {RaffleUtils.needsBacker(raffle) && (
      <li className="mb1">
        <Progress percent={Math.round((raffle.backerCount / raffle.minBackers) * 100)} />
      </li>
      )}
      <li className="mb1">
        {/* for backer campaign, the pledge period ends at the start of the raffle */}
        <CountDown
          timestamp={RaffleUtils.isBackerCampaign(raffle) ? raffle.startAt : raffle.endAt}
        />
        {RaffleUtils.isLive(raffle) && (
        <>
          <Divider type="vertical" />
          <EntrantCount count={raffle.entrantCount + raffle.backerCount} />
        </>
        )}
      </li>
    </ul>
  </>
)

Subtitle.propTypes = {
  raffle: PropTypes.object.isRequired,
}

export default Subtitle
