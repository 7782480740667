import { UsergroupAddOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'

const MinBackerCount = ({ count }) => (
  <span>
    <UsergroupAddOutlined className="mr1" />
    Goal:
    {' '}
    {count}
  </span>
)

MinBackerCount.propTypes = {
  count: PropTypes.number,
}

MinBackerCount.defaultProps = {
  count: 0,
}

export default MinBackerCount
