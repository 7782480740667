import PropTypes from 'prop-types'
import React from 'react'
import { capitalize, isEmpty } from 'lodash'

import CountriesDatabase from '../../assets/doc/countries.json'

const Locations = ({ raffle }) => (
  <span>
    {(!isEmpty(raffle.states) && (
    <>
      {raffle.states.map((state) => (
        capitalize(state)
      )).join(' / ')}
    </>
    ))
  || (!isEmpty(raffle.countries) && (
    <>
        {raffle.countries.map((country) => (
          CountriesDatabase[country.toLowerCase()].id
        )).join(' / ')}
    </>
  ))}
  </span>
)

Locations.propTypes = {
  raffle: PropTypes.object.isRequired,
}

export default Locations
