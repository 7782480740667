import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import {
  Card,
  Col,
  Modal,
  Progress,
  Row,
  message,
} from 'antd'
import { isEmpty } from 'lodash'
import { evaluate } from 'mathjs'
import PropTypes from 'prop-types'
import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ReactFullScreenElement from 'react-fullscreen-element'

import RaffleActions from '../../actions/raffles'
import circle from '../../assets/img/circle.svg'
import demoButton from '../../assets/img/demo_button.svg'
import equalButton from '../../assets/img/equal_button.svg'
import logo from '../../assets/img/game_logo.svg'
import logoSmall from '../../assets/img/game_logo_small.svg'
import greaterButton from '../../assets/img/greater_button.svg'
import greenCircle from '../../assets/img/green_circle.svg'
import lessButton from '../../assets/img/less_button.svg'
import pauseButton from '../../assets/img/pause_button.svg'
import redCircle from '../../assets/img/red_circle.svg'
import resumeButton from '../../assets/img/resume_button.svg'
import rulesButton from '../../assets/img/rules_button.svg'
import startButton from '../../assets/img/start_button.svg'

import './game.css'

const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max))

const renderRules = () => (
  <>
    <p>
      <span className="bold">
        Do not close the browser before completing your chance(s).
      </span>
    </p>
    <p>
      <span className="bold">
        Answer all questions correctly in order to get to the next level.
      </span>
    </p>
    <p>
      <span className="bold">
        Make sure that you have seen the
        {' '}
        <a
          target="_blank"
          href="../demo"
        >
          <img className="demo_button" src={demoButton} alt="demo" />
        </a>
        {' '}
        of the game before you start.
      </span>
    </p>
  </>
)

const squares1to5 = [
  [2, 4],
  [3, 9],
  [4, 16],
  [5, 25],
  [6, 36],
]
const squares1to10 = [
  [2, 4],
  [3, 9],
  [4, 16],
  [5, 25],
  [6, 36],
  [7, 49],
  [8, 64],
  [9, 81],
  [10, 100],
]
const levelOps = {
  levelUp: 5,
  1: {
    points: 100,
    values: [
      [2, 7],
      [5, 5],
      [8, 5],
      [-1, 0],
      [4, 2],
    ],
  },
  2: {
    points: 200,
    values: [
      [-5, -3],
      [7, 9],
      [2, 1],
      [-8, -9],
      [4, -4],
    ],
  },
  3: {
    points: 300,
    digits: 2,
    rules: [
      { range: [-5, 5], op: '+', type: 'range' },
    ],
  },
  4: {
    points: 400,
    digits: 2,
    rules: [
      { range: [-9, 5], op: '+', type: 'range' },
    ],
  },
  5: {
    points: 500,
    digits: 2,
    rules: [
      { range: [-9, 9], op: '+', type: 'range' },
    ],
  },
  6: {
    points: 600,
    digits: 2,
    rules: [
      { range: [-9, 15], op: '+', type: 'range' },
    ],
  },
  7: {
    points: 700,
    digits: 2,
    rules: [
      { range: [-15, 15], op: '+', type: 'range' },
    ],
  },
  8: {
    points: 800,
    digits: 3,
    rules: [
      { range: [-9, 15], op: '+', type: 'range' },
      { range: [1, 5], op: '*', type: 'range' },
      { range: squares1to5, op: '/', type: 'array' },
    ],
  },
  9: {
    points: 900,
    digits: 4,
    rules: [
      { range: [-9, 15], op: '+', type: 'range' },
      { range: [1, 9], op: '*', type: 'range' },
      { range: squares1to5, op: '/', type: 'array' },
    ],
  },
  10: {
    points: 1000,
    digits: 5,
    rules: [
      { range: [1, 15], op: '+', type: 'range' },
      { range: [1, 10], op: '*', type: 'range' },
      { range: [1, 15], op: '-', type: 'range' },
      { range: squares1to10, op: '/', type: 'array' },
    ],
  },
}

const TIMER = 60

class Game extends React.Component {
  state = {
    WrongAns: [],
    bestScore: {},
    currentLevel: 1,
    currentLevelQuestion: 1,
    currentLife: 0,
    extra: 0,
    isRulesShown: false,
    key: new Date().getTime(),
    leftNumber: 0,
    progress: false,
    progressState: 0,
    remainingTime: TIMER,
    rightNumber: 0,
    score: 0,
    showResults: false,
    startGame: true,
    startTime: 0,
    startTimer: false,
    timePlayed: 0,
    timer: TIMER,
    initialTimePlayed: 0,
    timestamps: [],
  }

  constructor(props) {
    super(props)

    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.selectLeft = this.selectLeft.bind(this)
    this.selectRight = this.selectRight.bind(this)
    this.selectEqual = this.selectEqual.bind(this)
    this.generateRandomExpression = this.generateRandomExpression.bind(this)
    this.leftNumber = this.leftNumber.bind(this)
    this.rightNumber = this.rightNumber.bind(this)
    this.getLevelStatus = this.getLevelStatus.bind(this)
    this.showModal = this.showModal.bind(this)
    this.endGame = this.endGame.bind(this)
    this.setRemainingTime = this.setRemainingTime.bind(this)
    this.pauseTimer = this.pauseTimer.bind(this)
    this.restartGame = this.restartGame.bind(this)
    this.getRemainingTime = this.getRemainingTime.bind(this)
    this.saveScore = this.saveScore.bind(this)
    this.startGame = this.startGame.bind(this)
    this.calculateTimePlayed = this.calculateTimePlayed.bind(this)
    this.showRules = this.showRules.bind(this)
  }

  componentDidMount() {
    const { player } = this.props

    if (player.entries === 4) {
      if (Number(player.product.gamechances) === player.gamescore.life) {
        this.setState({
          startGame: true,
          currentLife: +player.product.gamechances,
          bestScore: player.gamescore,
          showResults: true,
        })
      } else {
        this.setState({
          currentLife: player.gamescore.life,
          bestScore: player.gamescore,
        })
      }
    } else {
      this.leftNumber()
      this.rightNumber()
    }
  }

  getLevelStatus(num) {
    const { currentLevelQuestion, WrongAns } = this.state
    if (currentLevelQuestion <= num) {
      return <img className="circle" src={circle} key={num} alt="empty" />
    } if (WrongAns.indexOf(num) < 0) {
      return <img className="circle" src={greenCircle} key={num} alt="right" />
    } if (WrongAns.indexOf(num) >= 0) {
      return <img className="circle" src={redCircle} key={num} alt="wrong" />
    }
    return null
  }

  getRemainingTime() {
    const { extra, remainingTime } = this.state
    return TIMER + extra - remainingTime
  }

  setRemainingTime() {
    const { remainingTime } = this.state
    this.setState({ remainingTime: remainingTime - 1 })
  }

  getLifes() {
    const { player } = this.props
    const { currentLife } = this.state

    const lifes = +player.product.gamechances
    const diff = lifes - currentLife

    if (lifes > 0 && diff > 0) {
      return (
        <>
          <p>
            You have
            <span className="m1">{diff}</span>
            more chance(s)
          </p>
          <p>Click to play</p>
          <p>
            <button type="button" onClick={this.restartGame}>
              <img
                src={startButton}
                className="start_button"
                alt="start"
              />
            </button>
          </p>
          <p>
            <button type="button" onClick={this.showRules}>
              <img
                className="rules_button"
                src={rulesButton}
                alt="rules"
              />
            </button>
          </p>
        </>
      )
    }
    return (
      <p>
        Thank you for playing!
        <br />
        Your Best score will be emailed to you!
      </p>
    )
  }

  getHomeButtons() {
    const { score } = this.state
    if (score > 0) {
      return (
        <div className="square-container">
          <div className="square">
            <p>
              <img src={logo} style={{ width: '90%' }} alt="logo" />
            </p>
            <p>
              Chances:
              {this.currentLife()}
            </p>
            <p>
              <button type="button" onClick={this.startTimer}>
                <img
                  src={resumeButton}
                  className="resume_button"
                  alt="resume"
                />
              </button>
            </p>
            <p>Resume</p>
          </div>
        </div>
      )
    }
    return (
      <div className="square-container">
        <div className="square">
          <p>
            <img src={logo} style={{ width: '90%' }} alt="logo" />
          </p>
          <p>
            Chances:
            {this.currentLife()}
          </p>
          <p>
            <button type="button" onClick={this.startTimer}>
              <img
                src={startButton}
                className="start_button"
                alt="start"
              />
            </button>
          </p>
          <p>
            <button type="button" onClick={this.showRules}>
              <img
                className="rules_button"
                src={rulesButton}
                alt="rules"

              />
            </button>
          </p>
        </div>
      </div>
    )
  }

  renderTime = ({ remainingTime }) => (
    <div className="timer">
      <div className="value">{remainingTime}</div>
    </div>
  );

  startTimer() {
    const { click } = this.props
    const { timer, remainingTime, currentLife } = this.state
    click.play()
    this.setState({ startTime: new Date().getTime() })
    if (remainingTime === timer) {
      const i = currentLife
      this.setState({ currentLife: i + 1 })
    }
    this.setState({ startTimer: true })
    this.leftNumber()
    this.rightNumber()
  }

  restartGame() {
    const { currentLife } = this.state
    const life = currentLife + 1
    this.setState(
      {
        extra: 0,
        timer: TIMER,
        startTimer: true,
        key: new Date().getTime(),
        score: 0,
        currentLevel: 1,
        WrongAns: [],
        currentLevelQuestion: 1,
        leftNumber: 0,
        rightNumber: 0,
        remainingTime: TIMER,
        showResults: false,
        currentLife: life,
        startTime: new Date().getTime(),
        timePlayed: 0,
        initialTimePlayed: 0,
        timestamps: [],
      },
      () => {
        this.leftNumber()
        this.rightNumber()
      },
    )
  }

  stopTimer() {
    const { click } = this.props
    click.play()
    this.setState({ timer: TIMER, startTimer: false, showResults: true })
  }

  calculateLastClick() {
    const { startTime, initialTimePlayed, timestamps } = this.state
    const now = new Date().getTime()
    this.setState({
      timestamps: [...timestamps, now - startTime + initialTimePlayed],
    })
  }

  calculateTimePlayed() {
    const { startTime, timePlayed, initialTimePlayed } = this.state
    const now = new Date().getTime()
    const newTimePlayed = (now - startTime) + timePlayed
    this.setState({
      timePlayed: newTimePlayed,
      initialTimePlayed: initialTimePlayed + (now - startTime),
    })
    return newTimePlayed
  }

  pauseTimer() {
    const { click } = this.props
    click.play()
    const timePlayed = this.calculateTimePlayed()
    const newRemainingTime = TIMER - Math.floor(timePlayed / 1000)
    this.setState({ remainingTime: newRemainingTime }, () => {
      this.setState({ startTimer: false })
    })
  }

  startGame() {
    const { progressState } = this.state
    this.setState(
      {
        progress: true,
        isRulesShown: false,
      },
      () => {
        const timerId = setInterval(() => {
          const percent = progressState
          this.setState({
            progressState: percent + 20,
          })
        }, 100)
        setTimeout(() => {
          clearInterval(timerId)
          this.setState(
            {
              progressState: 100,
            },
            () => this.setState(
              {
                progress: false,
                startGame: true,
              },
              () => this.startTimer(),
            ),
          )
        }, 1000)
      },
    )
  }

  endGame() {
    const timePlayed = this.calculateTimePlayed()
    const newRemainingTime = TIMER - Math.floor(timePlayed / 1000)
    this.setState({ remainingTime: newRemainingTime }, () => {
      this.saveScore()
      this.setState({
        timer: TIMER,
        startTimer: false,
        key: new Date().getTime(),
        currentLevel: 1,
        WrongAns: [],
        currentLevelQuestion: 1,
        leftNumber: 0,
        rightNumber: 0,
        showResults: true,
      })
    })
  }

  showModal(res, i) {
    const { right, wrong } = this.props
    const secondsToGo = 5
    let modal = null
    if (res === true) {
      right.play()
      modal = Modal.success({
        content: 'That\'s Right..!',
        okButtonProps: { style: { display: 'none' } },
        centered: true,
      })
    } else if (res === false) {
      wrong.play()
      modal = Modal.error({
        content: 'Oops, that\'s wrong',
        okButtonProps: { style: { display: 'none' } },
        centered: true,
      })
    }
    setTimeout(() => {
      modal.destroy()
      if (right) {
        right.pause()
        right.currentTime = 0
      }
      if (wrong) {
        wrong.pause()
        wrong.currentTime = 0
      }
    }, secondsToGo * 100)
    this.calculateLastClick()
    const { currentLevel, WrongAns } = this.state
    let q = 0
    if (i < levelOps.levelUp) q = i + 1
    else {
      q = 1
      const lvl = currentLevel
      if (WrongAns.length === 0) this.setState({ currentLevel: lvl + 1 })
      else {
        setTimeout(() => {
          this.endGame()
        }, secondsToGo * 100)
        return
      }
    }
    this.setState(
      {
        currentLevelQuestion: q,
      },
      () => {
        this.leftNumber()
        this.rightNumber()
      },
    )
  }

  selectLeft() {
    let newScore = null
    const {
      currentLevelQuestion, leftNumber, rightNumber, WrongAns, score, currentLevel,
    } = this.state
    const i = currentLevelQuestion
    if (evaluate(leftNumber) <= evaluate(rightNumber)) {
      const newAry = WrongAns.concat(i)
      if (i === 5) {
        const modal = Modal.error({
          content: 'Oops, that\'s wrong',
          okButtonProps: { style: { display: 'none' } },
          centered: true,
        })
        setTimeout(() => {
          modal.destroy()
        }, 500)
      }
      newScore = score - levelOps[currentLevel].points
      if (newScore < 0) newScore = 0
      this.setState({
        score: newScore,
        WrongAns: newAry,
      }, () => {
        this.showModal(false, i)
      })
    } else {
      newScore = score + (i * levelOps[currentLevel].points)
      this.setState({ score: newScore }, () => {
        this.showModal(true, i)
      })
    }
  }

  selectRight() {
    let newScore = null
    const {
      currentLevelQuestion, leftNumber, rightNumber, WrongAns, score, currentLevel,
    } = this.state
    const i = currentLevelQuestion
    if (evaluate(leftNumber) >= evaluate(rightNumber)) {
      const newAry = WrongAns.concat(i)
      if (i === 5) {
        const modal = Modal.error({
          content: 'Oops, that\'s wrong',
          okButtonProps: { style: { display: 'none' } },
          centered: true,
        })
        setTimeout(() => {
          modal.destroy()
        }, 500)
      }
      newScore = score - levelOps[currentLevel].points
      if (newScore < 0) newScore = 0
      this.setState({
        score: newScore,
        WrongAns: newAry,
      }, () => {
        this.showModal(false, i)
      })
    } else {
      newScore = score + (i * levelOps[currentLevel].points)
      this.setState({ score: newScore }, () => {
        this.showModal(true, i)
      })
    }
  }

  selectEqual() {
    let newScore = null
    const {
      currentLevelQuestion, leftNumber, rightNumber, WrongAns, score, currentLevel,
    } = this.state
    const i = currentLevelQuestion
    if (evaluate(leftNumber) !== evaluate(rightNumber)) {
      const newAry = WrongAns.concat(i)
      if (i === 5) {
        const modal = Modal.error({
          content: 'Oops, that\'s wrong',
          okButtonProps: { style: { display: 'none' } },
          centered: true,
        })
        setTimeout(() => {
          modal.destroy()
        }, 500)
      }
      newScore = score - levelOps[currentLevel].points
      if (newScore < 0) newScore = 0
      this.setState({
        score: newScore,
        WrongAns: newAry,
      }, () => {
        this.showModal(false, i)
      })
    } else {
      newScore = score + (i * levelOps[currentLevel].points)
      this.setState({ score: newScore }, () => {
        this.showModal(true, i)
      })
    }
  }

  generateRandomExpression() {
    const { currentLevel } = this.state
    let i = 0
    const dg = levelOps[currentLevel].digits
    let str = ''
    while (i < dg) {
      const tmparry = levelOps[currentLevel].rules
      const opIdx = getRandomInt(tmparry.length)
      if (tmparry[opIdx].type === 'range') {
        const min = tmparry[opIdx].range[0]
        const max = tmparry[opIdx].range[1]
        let num = Math.floor(Math.random() * (max - min) + min)
        if (i > 0) {
          str += `${tmparry[opIdx].op} `
          if (num < 0) {
            num = Math.abs(num)
          }
        }
        str += `${num.toString()} `
      } else if (tmparry[opIdx].type === 'array') {
        const idx = getRandomInt(tmparry[opIdx].range.length)
        const numrtr = tmparry[opIdx].range[idx][1]
        const denomtr = tmparry[opIdx].range[idx][0]
        if (i > 0) {
          const ops = ['+', '-']
          const j = getRandomInt(ops.length)
          str += `${ops[j]} `
        }
        str
          += `${numrtr.toString() + tmparry[opIdx].op + denomtr.toString()} `
      }
      i += 1
    }
    return str
  }

  leftNumber() {
    const { currentLevel, currentLevelQuestion } = this.state
    let exp = ''
    if (currentLevel <= 2) {
      // array destructuring at index 0
      [exp] = levelOps[currentLevel].values[currentLevelQuestion - 1]
    } else {
      exp = this.generateRandomExpression()
    }
    this.setState({
      leftNumber: exp,
    })
  }

  rightNumber() {
    let exp = ''
    const { currentLevel, currentLevelQuestion } = this.state
    if (currentLevel <= 2) {
      // array destructuring at index 1
      [, exp] = levelOps[currentLevel].values[currentLevelQuestion - 1]
    } else {
      exp = this.generateRandomExpression()
    }
    this.setState({
      rightNumber: exp,
    })
  }

  currentLife() {
    const { player } = this.props
    const { currentLife } = this.state
    return (player.product.gamechances - currentLife)
  }

  showRules() {
    this.setState({
      isRulesShown: true,
    })
  }

  async saveScore() {
    const { raffle, player } = this.props
    const {
      currentLife, score, timestamps, extra, bestScore,
    } = this.state
    const sendEmail = currentLife === Number(player.product.gamechances)
    const newScore = {
      score,
      timePlayed: this.getRemainingTime(),
      timePlayedMillisecs: timestamps,
      timeGiven: TIMER + extra,
      life: currentLife,
    }
    if (
      isEmpty(bestScore)
      || bestScore.score <= score
    ) {
      this.setState({
        bestScore: newScore,
      })
      try {
        await RaffleActions.updateGameScore(
          raffle,
          player,
          newScore,
          sendEmail,
        )
      } catch (ex) {
        message.warn(`${ex.message} Please Play Again!`)
      }
    } else {
      const updateScore = {
        score: bestScore.score,
        timePlayed: bestScore.timePlayed,
        timeGiven: bestScore.timeGiven,
        life: currentLife,
        timePlayedMillisecs: bestScore.timePlayedMillisecs,
      }
      try {
        await RaffleActions.updateGameScore(
          raffle,
          player,
          updateScore,
          sendEmail,
        )
      } catch (ex) {
        message.warn(`${ex.message} Please Play Again!`)
      }
    }
  }

  renderDesktopView() {
    const { startTimer, score, currentLevel } = this.state
    return (
      <>
        <Col md={3} className="xs-hide sm-hide center">
          Level
          {' '}
          {currentLevel}
        </Col>
        <Col md={9} className="xs-hide sm-hide center">
          {this.getLevelStatus(1)}
          {this.getLevelStatus(2)}
          {this.getLevelStatus(3)}
          {this.getLevelStatus(4)}
          {this.getLevelStatus(5)}
        </Col>
        <Col md={4} className="xs-hide sm-hide center">
          Score:
          {' '}
          {score}
        </Col>
        <Col md={3} className="xs-hide sm-hide center">
          Pause
          {' '}
          <br />
          {startTimer && (
            <button type="button" onClick={this.pauseTimer}>
              <img
                className="pause_button"
                src={pauseButton}
                alt="pause"
              />
            </button>
          )}
        </Col>
      </>
    )
  }

  renderMobileView() {
    const { startTimer, score, currentLevel } = this.state
    return (
      <>
        <Col xs={12} md={4} className="lg-hide md-hide center">
          <span className="mx-auto">
            Pause
            {' '}
            <br />
            {startTimer && (
              <button type="button" onClick={this.pauseTimer}>
                <img
                  className="pause_button"
                  src={pauseButton}
                  alt="pause"
                />
              </button>
            )}
          </span>
        </Col>
        <Col xs={24} className="lg-hide md-hide">
          <Row className="center" justify="center" align="middle">
            <Col xs={3} className="center pb1 mr2">
              Level:
              {' '}
              {currentLevel}
            </Col>
            <Col xs={16} className="center pb1">
              {this.getLevelStatus(1)}
              {this.getLevelStatus(2)}
              {this.getLevelStatus(3)}
              {this.getLevelStatus(4)}
              {this.getLevelStatus(5)}
            </Col>
            <Col xs={3} className="center pb1">
              Score:
              {' '}
              {score}
            </Col>
          </Row>
        </Col>
      </>
    )
  }

  render() {
    const {
      startTimer,
      isRulesShown,
      progressState,
      progress,
      startGame,
      showResults,
      bestScore,
      key,
      timer,
      remainingTime,
      leftNumber,
      rightNumber,
      currentLevel,
      currentLevelQuestion,
      WrongAns,
    } = this.state
    return (
      <ReactFullScreenElement
        fullScreen={startTimer}
        allowScrollbar
      >
        {isRulesShown && (
          <Card className="rules">
            <img className="rules_button" src={rulesButton} alt="rules" />
            {renderRules()}
            <div className="center-div">
              <div className="center-btn">
                <button type="button" onClick={this.startGame}>
                  <img
                    src={startButton}
                    className="start_button"
                    alt="start"
                  />
                </button>
              </div>
            </div>
            <span style={{ float: 'left' }}>
              <img src={logoSmall} alt="logo" className="logo_small" />
            </span>
            <span style={{ float: 'right' }}>
              Chances Left:
              <span className="ml1">{this.currentLife()}</span>
            </span>
          </Card>
        )}
        {progress && (
          <Card className="rules">
            <Progress percent={progressState} />
          </Card>
        )}
        {!isRulesShown
          && startGame
          && showResults && (
            <Card className="rules">
              <div className="square-container">
                <div className="square">
                  <img src={logo} style={{ width: '90%' }} alt="logo" />
                  <p className="score">
                    Your Best Score:
                    {' '}
                    {bestScore.score}
                    <br />
                    Time Played:
                    {' '}
                    {bestScore.timePlayed}
                    {' '}
                    Seconds
                    <br />
                    Time Given:
                    {' '}
                    {bestScore.timeGiven}
                    {' '}
                    Seconds
                  </p>
                  {this.getLifes()}
                </div>
              </div>
              <span style={{ float: 'right' }}>
                {' '}
                Chances Left:
                <span className="ml1">{this.currentLife()}</span>
              </span>
            </Card>
        )}
        {startGame
          && !showResults
          && !startTimer
          && !isRulesShown && (
            <Card className="game-card">{this.getHomeButtons()}</Card>
        )}
        {startGame
          && !showResults
          && startTimer && (
            <Card className="game-card">
              <h3 className="center">Determine Which Side is Greater</h3>
              <Row
                type="flex"
                align="middle"
                justify="center"
                className="center"
              >
                <Col xs={12} md={4} className="center">
                  <span className="max-auto">
                    Timer
                    <CountdownCircleTimer
                      size={50}
                      strokeWidth={8}
                      colors={[['#66FF33', 0.33], ['#F7B801', 0.33], ['#A30000']]}
                      key={key}
                      isPlaying={startTimer}
                      duration={timer}
                      initialRemainingTime={remainingTime}
                      onComplete={() => {
                        this.setState(
                          {
                            remainingTime: 0,
                          },
                          () => {
                            this.calculateTimePlayed()
                            this.saveScore()
                            this.stopTimer()
                          },
                        )
                      }}
                    >
                      {this.renderTime}
                    </CountdownCircleTimer>
                  </span>
                </Col>
                {this.renderDesktopView()}
                {this.renderMobileView()}
              </Row>
              <div className="center">
                <Row>
                  <Col xs={11} md={11} className="m1 exps">
                    {leftNumber}
                  </Col>
                  <Col xs={11} md={11} className="m1 exps">
                    {rightNumber}
                  </Col>
                </Row>
                <Row className="my1 center">
                  <Col xs={8} md={8} className="center">
                    <button type="button" onClick={this.selectLeft}>
                      <img
                        className="symbols"
                        src={lessButton}
                        alt="left"
                      />
                    </button>
                  </Col>
                  <Col xs={8} md={8} className="center">
                    <button type="button" onClick={this.selectEqual}>
                      <img
                        className="symbols"
                        src={equalButton}
                        alt="equal"
                      />
                    </button>
                  </Col>
                  <Col xs={8} md={8} className="center">
                    <button type="button" onClick={this.selectRight}>
                      <img
                        className="symbols"
                        src={greaterButton}
                        alt="right"
                      />
                    </button>
                  </Col>
                </Row>
              </div>
              <div style={{ float: 'left', textAlign: 'center', fontSize: 25 }}>
                <CloseOutlined className="mr1" style={{ color: 'red' }} />
                <span className="mr1">
                  {' '}
                  {WrongAns.length}
                  {' '}
                </span>
                <CheckOutlined className="mr1" style={{ color: 'rgb(102, 255, 51)' }} />
                <span>
                  {(currentLevel === 1
                    ? currentLevelQuestion - 1
                    : (currentLevel - 1) * 5 + (currentLevelQuestion - 1))
                    - WrongAns.length}
                </span>
              </div>
              <div style={{ float: 'right', textAlign: 'center' }}>
                Chances Left:
                <span className="ml1">{this.currentLife()}</span>
              </div>
            </Card>
        )}
      </ReactFullScreenElement>
    )
  }
}

Game.propTypes = {
  raffle: PropTypes.object.isRequired,
  player: PropTypes.object.isRequired,
  click: PropTypes.object.isRequired,
  right: PropTypes.object.isRequired,
  wrong: PropTypes.object.isRequired,
}

export default Game
